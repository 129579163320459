





























import { Component, Vue } from 'vue-property-decorator';

@Component
export default class SpeedControls extends Vue {
  changeSpeed(value: 'increase' | 'decrease' | 'regular') {
    this.$emit('changeSpeed', value);
  }
}
