





































import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import formatTime from '../util/format-time';
import ControlButton from './ControlButton.vue';
import { config } from '@/config';
import { PlayerController } from '@/services/player-controller';

@Component({
  components: {
    ControlButton,
  },
})
export default class TrackList extends Vue {
  @Prop() readonly tracks!: PlaylistTrackInfo[];
  @Prop() readonly currentTrack!: string;
  @Prop() readonly disableControls!: boolean;
  @Prop() readonly playing!: boolean;
  @Prop({ type: Number, default: 1 }) private tabindex!: number;

  @Inject() private controller!: PlayerController;
  @Inject() private os!: string;

  formatTime(time: number): string {
    return formatTime(time, false);
  }

  togglePlay(story: PlaylistTrackInfo['story']): void {
    this.playing && this.currentTrack === story.id
      ? this.controller.pause()
      : this.controller.play();
  }

  onKey(e: KeyboardEvent, story: PlaylistTrackInfo['story']) {
    if (e.code === 'KeyK' && e.altKey) {
      this.togglePlay(story);
    } else {
      this.$emit(e.type, e);
    }
  }

  listItemClick(track: PlaylistTrackInfo, index: number) {
    if (this.currentTrack !== track.story.id) {
      this.controller.goto(index);
    }
  }

  ariaLabel(
    channel: string,
    selectedTrack: string,
    currentTrack: boolean
  ): string {
    const label = config.aria.labels[`play${this.os}`];
    const playText = `From ${channel}: ${selectedTrack}, ${label}`;
    if (!currentTrack) {
      return playText;
    } else {
      if (!this.playing) {
        return playText;
      } else {
        return config.aria.labels[`pause${this.os}`];
      }
    }
  }
}
