const serviceFormat = (service: string) => {
  const subscribeText = 'Subscribe on ';

  switch (service) {
    case 'google':
      return `${subscribeText}Google Podcasts`;
    case 'googleAssistant':
      return `${subscribeText}Google Assistant`;
    case 'itunes':
      return `${subscribeText}Apple Podcasts`;
    case 'alexa':
      return `${subscribeText}Amazon Alexa`;
    case 'spotify':
      return `${subscribeText}Spotify`;
    case 'stitcher':
      return `${subscribeText}Stitcher`;
    case 'pandora':
      return `${subscribeText}Pandora`;
    default:
      return subscribeText + service;
  }
};

export { serviceFormat };
