import { Component, Inject, Ref, Vue } from 'vue-property-decorator';
import { config } from '@/config';
import { SubscriptionManager } from '@/core/utils';
import { PlayerController, PlayerState } from '@/services/player-controller';
import { ImageAnalyzer } from '@/services/image-analyzer';

import { onKeyDown } from '@/util/keyboard-shortcuts';
import ControlButton from '@/components/ControlButton.vue';
import PlaybackScrubber from '@/components/PlaybackScrubber.vue';
import PlaybackScrubberRadial from '@/components/PlaybackScrubberRadial.vue';
import TrackList from '@/components/TrackList.vue';
import SpeedControls from '@/components/SpeedControls.vue';
import { serviceFormat } from '@/util/service-format';

Component.registerHooks(['metaInfo']);

@Component({
  components: {
    ControlButton,
    PlaybackScrubber,
    PlaybackScrubberRadial,
    TrackList,
    SpeedControls,
  },
})
export default class PlayerV3Base extends Vue implements VueComponentLifecycle {
  state: PlayerState = {};
  currentTime = 0;
  audioDuration = 0;
  buffered = 0;
  playbackSpeed = 1;
  bodyHeight = document.body.clientHeight;

  config = config;
  primaryThemeColor = config.theme.defaultColor;

  initiallyLoaded = false;

  trackSize = config.page.pageSize - 1;
  breakSize = config.page.breakSize;

  /* eslint-disable-next-line no-invalid-this */
  trackInterval = [0, this.breakSize];

  @Inject() readonly os!: string;
  @Inject() protected controller!: PlayerController;
  @Inject() private imageAnalyzer!: ImageAnalyzer;
  @Ref() private thumbnailImg!: HTMLImageElement;
  private subscriptions!: SubscriptionManager;

  get ariaContentLabel(): string {
    const _label = this.label(`play${this.os}`);

    return !this.playing
      ? `From ${this.state.channelName}: ${this.trackTitle}, ${_label}`
      : this.label(`pause${this.os}`);
  }

  get disableControls(): boolean {
    return !!(this.state.loading || this.state.suspended);
  }

  get darkTheme(): boolean {
    switch (this.state.settings?.design.theme) {
      case 'dark':
        return true;
      case 'light':
        return false;
      default:
        return window.matchMedia('(prefers-color-scheme: dark)').matches;
    }
  }

  get icon(): string {
    return this.playing ? 'pause' : 'play';
  }

  get playing(): boolean {
    return !!this.state.playing;
  }

  get currentTrack(): string {
    return this.state.story ? this.state.story.id : '';
  }

  get trackTitle(): string {
    return this.state.story ? this.state.story.content.title : '';
  }

  get trackSubtitle(): string {
    return this.state.story ? this.state.story.content.summary : '';
  }

  get thumbnailSrc(): string {
    return this.state.publisher
      ? this.state.publisher.display.thumbnail.small ||
          this.state.publisher.display.thumbnail.default
      : '';
  }

  get thumbnailAltTag(): string {
    return this.state.publisher && this.state.publisher.channelName
      ? `${this.state.publisher.channelName} Thumbnail`
      : 'Thumbnail';
  }

  get reverbUrl(): string {
    if (this.state.shareUrl) {
      return this.state.shareUrl;
    } else if (this.state.story) {
      return `https://play.spokenlayer.com/${this.state.story.publisherId}?utm_source=player&utm_medium=share`;
    }
    return '';
  }

  get trackIndex(): number | null {
    if (this.state.story && this.state.trackList) {
      return this.state.trackList.findIndex(
        item => item.story.id === this.state.story!.id
      );
    } else {
      return null;
    }
  }

  get hasPrevious(): boolean {
    return this.trackIndex !== null && this.trackIndex > this.trackInterval[0];
  }

  get hasNext(): boolean {
    return (
      this.trackIndex !== null &&
      this.trackIndex < this.trackInterval[1] &&
      this.trackIndex < this.state.trackList!.length
    );
  }

  get channelName(): string {
    return this.state.publisher && this.state.publisher.channelName
      ? this.state.publisher.channelName
      : '';
  }

  get channelDescription(): string {
    return this.state.publisher && this.state.publisher.description
      ? this.state.publisher.description.info
      : '';
  }

  get subscribeUrls(): Dictionary {
    const urls: Dictionary = {};
    if (this.state.publisher && this.state.publisher.urls) {
      const publisherUrls = this.state.publisher.urls;
      const excludedServices = [
        'customSkill',
        'flashBriefing',
        'googleAction',
        'narrativeNews',
        'share',
        'website',
        'iheartRadio',
        'overcast',
      ];
      let service: string;

      for (service in publisherUrls) {
        if (
          excludedServices.findIndex(
            excludedService => excludedService === service
          ) < 0
        ) {
          if (publisherUrls[service]) {
            urls[service] = this.state.publisher.urls[service];
          }
        }
      }

      const googleActionLink = publisherUrls && publisherUrls.googleAction;
      const alexaSkillLink =
        publisherUrls &&
        (publisherUrls.flashBriefing || publisherUrls.customSkill);

      if (googleActionLink) {
        urls['googleAssistant'] = googleActionLink;
      }

      if (alexaSkillLink) {
        urls['alexa'] = alexaSkillLink;
      }
    }

    return urls;
  }

  get showTrackList(): boolean {
    return this.bodyHeight >= 600;
  }

  isMobile(): boolean {
    if (navigator.userAgentData) {
      return navigator.userAgentData.mobile;
    } else {
      const mobileAgents = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i];

      return mobileAgents.some(mobileAgent => {
        return navigator.userAgent.match(mobileAgent);
      });
    }
  }

  label(button: string): string {
    return config.aria.labels[button];
  }

  formattedService(service: string): string {
    return serviceFormat(service);
  }

  onKey(e: KeyboardEvent): void {
    if (e.code === 'KeyN' && this.trackIndex! > 3) {
      return;
    } else if (e.code === 'KeyP' && this.trackIndex! > 0) {
      this.playbackSpeed = 1;
    } else if (e.code === 'BracketLeft' && e.altKey) {
      this.changeSpeed('decrease');
    } else if (e.code === 'BracketRight' && e.altKey) {
      this.changeSpeed('increase');
    } else if (e.code === 'Backslash' && e.altKey) {
      this.changeSpeed('regular');
    }

    return onKeyDown(e, this.controller, this.state, this.os);
  }

  metaInfo() {
    return {
      meta: [
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: this.channelName },
        { name: 'twitter:description', content: this.channelDescription },
        {
          name: 'twitter:image:src',
          content: this.transformedThumbnail('twitter'),
        },
        { property: 'og:title', content: this.channelName },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: this.reverbUrl },
        { property: 'og:description', content: this.channelDescription },
        { property: 'og:site_name', content: this.channelName },
        { property: 'og:image', content: this.transformedThumbnail('fb') },
        { property: 'og:image:alt', content: `${this.channelName} logo` },
        { property: 'fb:app_id', content: config.og.appId },
      ],
    };
  }

  transformedThumbnail(platform: string): string {
    const w = platform === 'fb' ? 1200 : 800;
    const h = platform === 'fb' ? 630 : 418;
    return this.thumbnailSrc.replace(
      /\/image\/upload\/ar_1:1,c_fill\/.*\/d_SpokenLayer_Logo_Verticle_fb9a1b.png/gi,
      `/image/upload/ar_1:1,c_pad,w_${w},h_${h},dpr_auto,fl_lossy,f_auto,b_auto:border/d_SpokenLayer_Logo_Verticle_fb9a1b.png`
    );
  }

  changeSpeed(direction: 'decrease' | 'increase' | 'regular'): void {
    if (direction === 'decrease') {
      if (this.state.playing && this.playbackSpeed > 0.25) {
        this.playbackSpeed -= 0.25;
      }
    } else if (direction === 'increase') {
      if (this.state.playing && this.playbackSpeed < 2) {
        this.playbackSpeed += 0.25;
      }
    } else if (direction === 'regular') {
      this.playbackSpeed = 1;
    }

    this.controller.setPlaybackSpeed(this.playbackSpeed);

    const el = document.createElement('div');
    el.setAttribute('aria-live', 'polite');
    el.classList.add('sr-only');

    document.body.appendChild(el);

    const currSpeedText = ` Playback speed: ${this.playbackSpeed}`;

    window.setTimeout(function() {
      el.innerHTML = currSpeedText;
    }, 100);

    window.setTimeout(function() {
      document.body.removeChild(el);
    }, 500);
  }

  toggleMainPlay(state: string): void {
    const mainPlayButton = document.querySelector('.main-play') as HTMLElement;

    if (state === 'off') {
      mainPlayButton.setAttribute('aria-hidden', 'true');
      mainPlayButton.setAttribute('tabindex', '-1');

      // switch focus to tracklist if in soon to be hidden main play button
      const menuOpenButton = document.querySelector('.info-show');
      const menuCloseButton = document.querySelector('.info-close');
      const onDisappearingButton =
        document.activeElement === mainPlayButton ||
        document.activeElement === menuOpenButton ||
        document.activeElement === menuCloseButton;
      if (onDisappearingButton) {
        const tracklistPlayButton = document.querySelector(
          '.tracklist__track__play-button-wrapper'
        )!.firstChild;
        (tracklistPlayButton as HTMLElement).focus();
      }
    } else {
      mainPlayButton.setAttribute('aria-hidden', 'false');
      mainPlayButton.setAttribute('tabindex', '1');

      // switch focus to main play button if on soon to be hidden tracklist
      if (
        document.activeElement?.parentElement!.className.includes('tracklist')
      ) {
        mainPlayButton.focus();
      }
    }
  }

  share() {
    try {
      navigator.share({
        title: this.channelName,
        text: this.trackTitle,
        url: this.reverbUrl,
      });
    } catch (err) {
      window.open(this.reverbUrl);
    }
  }
  /* lifecycle */

  created() {
    this.subscriptions = new SubscriptionManager();
    this.state = this.controller.state;
  }

  mounted() {
    // remove focus outline when receiving mouse input
    document.body.addEventListener('mousedown', function() {
      document.body.classList.add('using-mouse');
    });

    // add focus outline when receiving tab input
    document.body.addEventListener('keydown', function(event) {
      if (event.key === 'Tab') {
        document.body.classList.remove('using-mouse');
      }
    });

    this.subscriptions.watch<Partial<PlayerState>>(
      this.controller.updates$,
      update => {
        this.state = { ...this.state, ...update };

        if (update.audioDuration) {
          this.audioDuration = update.audioDuration;
        }

        if (update.buffered) {
          this.buffered = update.buffered;
        }
      }
    );
    this.subscriptions.watch<number>(
      this.controller.currentTime$,
      currentTime => {
        this.currentTime = currentTime;
      }
    );

    // Get theme color
    if (this.thumbnailImg) {
      this.subscriptions.watch<string>(
        this.imageAnalyzer.observeDominantColor(this.thumbnailImg),
        color => {
          if (this.thumbnailSrc) {
            this.primaryThemeColor = color;
            this.initiallyLoaded = true;
          }
        }
      );
    } else {
      this.initiallyLoaded = true;
    }

    if (this.isMobile()) {
      return;
    }

    window.addEventListener('focus', () => {
      const baseIcons = document.querySelectorAll('.subscribe-icon__base');
      const hoverIcons = document.querySelectorAll('.subscribe-icon__hover');
      for (let i = 0; i < baseIcons.length; i++) {
        baseIcons[i].classList.add('player-active');
        hoverIcons[i].classList.add('player-active');
      }
    });

    window.addEventListener('blur', () => {
      const baseIcons = document.querySelectorAll('.subscribe-icon__base');
      const hoverIcons = document.querySelectorAll('.subscribe-icon__hover');
      for (let i = 0; i < baseIcons.length; i++) {
        baseIcons[i].classList.remove('player-active');
        hoverIcons[i].classList.remove('player-active');
      }
    });
  }

  destroyed(): void {
    this.subscriptions.close();
  }
}
