



































































import { Component, Prop } from 'vue-property-decorator';
import formatTime from '../util/format-time';
import PlaybackScrubber from './PlaybackScrubber.vue';

@Component
export default class PlaybackScrubberRadial extends PlaybackScrubber {
  @Prop({ type: Number, default: 240 }) readonly diameter!: number;
  @Prop({ type: Number, default: 2 }) readonly staticBarStroke!: number;
  @Prop({ type: Number, default: 5 }) readonly progressBarStroke!: number;
  @Prop({ type: String, default: 'lime' }) readonly bufferStrokeColor!: string;
  @Prop({ type: String, default: 'green' })
  readonly transportStrokeColor!: string;

  get adjustedRadius(): number {
    return this.diameter / 2 - (this.progressBarStroke + 1) / 2;
  }

  get circumference(): number {
    return Math.PI * 2 * this.adjustedRadius;
  }

  get bufferBarTransform(): string {
    return Number(this.circumference - this.circumference * this.buffered)
      .toFixed(3)
      .toString();
  }

  get transportBarTransform(): string {
    if (!this.current || !this.total) {
      return this.circumference.toFixed(3).toString();
    }

    if (Math.abs(this.current - this.previous) > 1) {
      this.fast = true;
      setTimeout(() => {
        this.fast = false;
      }, 250);
    }

    this.previous = this.current;

    return Number(
      this.circumference - this.circumference * (this.current / this.total)
    )
      .toFixed(3)
      .toString();
  }

  formatTime(time: number): string {
    return formatTime(time, false);
  }

  onSeek(e: MouseEvent) {
    const {
      width,
      x,
      height,
      y,
    } = (this.progressBarWrapper.getBoundingClientRect() as unknown) as DOMRect;

    const offsetX: number = e.pageX - x - width / 2;
    const offsetY: number = e.pageY - y - height / 2;

    const percentage = (Math.PI - Math.atan2(offsetX, offsetY)) / (2 * Math.PI);

    this.$emit('seek', percentage);
    this.fast = true;
  }
}
